import React from 'react'
import Content from '../Content'

const ArticleTemplate = ({
  content,
  contentComponent,
  cover,
  title,
}) => {
  const PostContent = contentComponent || Content
  return (
    <div>
      <h1 className='title is-size-2 has-text-weight-bold is-bold-light'>
        {title}
      </h1>
      <img src={cover} alt={title} />
      <PostContent content={content} />
      <div style={{ marginTop: `4rem` }}>
      </div>
      <hr />
    </div>
  )
}

export default ArticleTemplate
